
.my-container {

  background: #eca7ff;
  border: 10px solid #78beff;


}


.agi-h1 {
  font-size: 2em;
  font-weight: bold;
  background-color: #ce0000;
  color: white;
  border-radius: 5px;
}



.agi-h2 {
  font-size: 1em;
  font-weight: normal;
  background-color: #e5e5e5;
  color: #42577f;
  padding: 20px;
  margin-top: 5px;
  border-radius: 5px;


  font-family: 'Francois One', sans-serif;


}




.agi-lista-1 {

  margin: 0 0 0 0;
  padding: 0 0 0 0;
  list-style: none;

}

.agi-lista-1 li {

  display: block;
  position: relative;
  padding: 5px 5px 5px 5px;
  background-color: #cbe7ff;
  margin-bottom: 1px;
  margin-top: 1px;
  border-radius: 6px;

}


.agi-lista-2 {

  margin: 0 0 0 0;
  padding: 0 0 0 0;
  list-style: none;

}

.agi-lista-2 li {


  display: block;
  position: relative;
  padding: 5px 5px 5px 5px;
  background-color: #f3f3f3;
  margin-bottom: 2px;
  margin-top: 2px;
  border-radius: 5px;

}

.loga img {
  display: inline-block;
  position: relative;
  height: 50px;
  margin-right: 10px;
}


.powrot {
  cursor: pointer;
}

.powrot:hover {
  text-decoration: underline;
}



